<template>
<div class="loading">
  <div v-for="item in 8" :key="item"></div>
</div>
</template>
<style>
.loading,
  .loading > div {
    position: relative;
    box-sizing: border-box;
  }

  .loading {
    display: block;
    font-size: 0;
    color: #000;
    width: 32PX;
    height: 32PX;
  }

  .loading.la-dark {
    color: #333;
  }

  .loading > div {
    display: inline-block;
    float: none;
    background-color: currentColor;
    border: 0 solid currentColor;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 8PX;
    height: 8PX;
    margin-top: -4PX;
    margin-left: -4PX;
    border-radius: 100%;
    animation: ball-spin-clockwise 1s infinite ease-in-out;
  }

  .loading > div:nth-child(1) {
    top: 5%;
    left: 50%;
    animation-delay: -0.875s;
  }

  .loading > div:nth-child(2) {
    top: 18.1801948466%;
    left: 81.8198051534%;
    animation-delay: -0.75s;
  }

  .loading > div:nth-child(3) {
    top: 50%;
    left: 95%;
    animation-delay: -0.625s;
  }

  .loading > div:nth-child(4) {
    top: 81.8198051534%;
    left: 81.8198051534%;
    animation-delay: -0.5s;
  }

  .loading > div:nth-child(5) {
    top: 94.9999999966%;
    left: 50.0000000005%;
    animation-delay: -0.375s;
  }

  .loading > div:nth-child(6) {
    top: 81.8198046966%;
    left: 18.1801949248%;
    animation-delay: -0.25s;
  }

  .loading > div:nth-child(7) {
    top: 49.9999750815%;
    left: 5.0000051215%;
    animation-delay: -0.125s;
  }

  .loading > div:nth-child(8) {
    top: 18.179464974%;
    left: 18.1803700518%;
    animation-delay: 0s;
  }

  @keyframes ball-spin-clockwise {
    0%,
    100% {
      opacity: 1;
      transform: scale(1);
    }

    20% {
      opacity: 1;
    }

    80% {
      opacity: 0;
      transform: scale(0);
    }
  }
</style>
