import {createStore} from 'vuex'

export default createStore({
    state: {
        merchant: {
            name: 'MPurse Services Pvt.ltd.',
            logo: '',
            amount: 0,
            endTime:null,
        },
        opsDisable:false
    },
    mutations: {
        updateMchName(state, merchantName) {
            state.merchant.name = merchantName
        },
        updateMchLogo(state, iconUrl) {
            state.merchant.logo = iconUrl
        },
        updateMchEndTime(state, endTime) {
          state.merchant.endTime = endTime
        },
        updateMchAmount(state, amount) {
          state.merchant.amount = amount
        },
        updateOpsStatus(state, isActive) {
          state.opsDisable = isActive
        },
    },
    actions: {},
    modules: {}
})