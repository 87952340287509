import axios from "axios";
import { Toast } from 'vant';

const sessionKey = "h5-session";

// 创建 axios 实例
const service = axios.create({
  timeout: 50000,
  headers: { "Content-Type": "application/json;charset=utf-8" },
});

// 请求拦截器
service.interceptors.request.use(
  (config) => {
    config.headers[sessionKey] = sessionStorage.getItem("sessionId");
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  (response) => {
    const res = response.data;
    if (res.code === 200 && res.data) {
      return res.data;
    } else {
      let noAlert = response.config.headers.noAlert || false;
      let message = res.message || "The request failed. Please contact the administrator";
      !noAlert && Toast(message);
      return Promise.reject(message);
    }
  },
  (error) => {
    let noAlert = error.config.headers.noAlert || false;
    let message = error.message || "The request failed. Please contact the administrator";
    !noAlert && Toast(message);
    return Promise.reject(message);
  }
);
// 导出 axios 实例
export default service;
