import request from "@/utils/request";

//校验此onetimeId是否有效，并返回session-id
export function paymentValidate(data,headers) {
  return request({
    url: "/h5/v1/gateway/validate",
    method: "post",
    headers,
    params: data,
  });
}
//返回有效数据
export function getPaymentConfig(data,headers) {
  return request({
    url: "/h5/v1/gateway/merchant/config",
    method: "post",
    headers,
    params: data,
  });
}
//UPI Intent 支付
export function upiIntentPayment(data, headers) {
  return request({
    url: "/h5/v1/gateway/upi/intent",
    method: "post",
    headers,
    params: data,
  });
}

// vpa验证
export function upiVpaCheck(data, headers) {
  return request({
    url: "/h5/v1/gateway/upi/vpaCheck",
    method: "post",
    headers,
    params: data,
  });
}

// 发起 UPI Collect 支付
export function upiCollectPayment(data, headers) {
  return request({
    url: "/h5/v1/gateway/upi/collect",
    method: "post",
    headers,
    params: data,
  });
}

//查询UPI支付结果
export function getPaymentResult(data, headers) {
  return request({
    url: "/h5/v1/gateway/payment/result",
    method: "post",
    headers,
    params: data,
  });
}

//H5 PagePay 支付
export function pagePayPayment(headers) {
  return request({
    url: "/h5/v1/gateway/page/dynamic",
    method: "post",
    headers,
  });
}
// h5 支付列表
export function getPayOptions(headers) {
  return request({
    url: "/h5/v1/gateway/payment/options",
    method: "post",
    headers,
  });
}