import {createRouter, createWebHashHistory} from "vue-router";
import PaymentMethod from "../views/PaymentMethod.vue";

const routes = [
    {
        path: "/",
        name: "PaymentMethod",
        component: PaymentMethod,
        meta: {title: "Payment Method"},
        children:[
          // {
          //   path: "",
          //   component: () => import("@/views/UPICollect.vue"),
          // },
          {
            path: "/qrCode",
            name: "QRCode",
            component: () => import("@/views/QRCode.vue"),
          },
          {
              path: "/upi",
              name: "UPI",
              component: () => import("@/views/UPICollect.vue"),
          },
          {
            path: "/upiCountdown",
            name: "UPICountdown",
            component: () => import("@/views/UPICountdown.vue"),
          },
        ]
    },
    {
        path: "/paymentResult",
        name: "PaymentResult",
        component: () => import("@/views/PaymentResult.vue"),
    },
    {
        path: "/getUUID",
        name: "UUID",
        component: () => import("@/views/getUUID.vue"),
    },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

export default router;
