import { createApp } from 'vue'
import Dialog from '../components/Dialog.vue'
import {useRouter} from "vue-router";
const router = useRouter();

// 创建一个挂载容器
const parentNode = document.createElement('div')
document.body.appendChild(parentNode)
function openModal(
  { 
    title = 'CANCEL PAYMENT?',
    content = 'Your payment is ongoing. Are you sure you want to cancel the payment?',
    type = 'confirm',
    confirmText = 'Continue to payment',
    cancelText = 'Yes, cancel',
    onCancel= () => {},
    onConfirm= () => {}
  } = Option
) {
  const app = createApp(Dialog, {
    title,
    content,
    type,
    confirmText,
    cancelText,
    cancelCall: () => {
      onCancel();
      unmount()
    },
    confirmCall: () => {
      onConfirm();
      unmount()
    },
  })
  // 卸载组件
  const unmount = () => {
    app.unmount()
  }
  // 挂载组件
  app.mount(parentNode)
  return app
}

export default openModal