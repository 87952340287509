<template>
  <div class="paymentMethod scroll-box flex flex-col h-full">
    <div
      ref="MethodBox"
      class="<xl:hidden method-title xl:h-20 xl:text-xl xl:mx-8 flex justify-between items-center font-medium text-$theme-color border-b-1 border-$theme-color-light-2"
    >
      <div class="left"></div>
      <span>Payment Options</span>
      <van-icon
        class="text-$theme-color"
        size="20"
        @click="close"
        name="cross"
      />
    </div>
    <div class="flex flex-1">
      <div class="w-59 bg-$theme-color-light-05 pt-8 pl-4 <xl:hidden">
        <div
          class="aside-radius block text-center rounded-l-3xl text-$theme-color text-xl font-medium leading-18 cursor-pointer"
          v-for="item in payTypeList"
          :key="item.code"
          :class="{
            'bg-$color-fff': selectCode === item.code,
            'opacity-50': store.state.opsDisable,
          }"
          @click="selectForward(item)"
        >
          {{ item.title }}
        </div>
      </div>
      <!-- 移动端 且 路由/ 显示 -->
      <div
        class="flex-1 mx-[28px] xl:hidden flex flex-col justify-between mb-[80px]"
        v-show="routePath === '/'"
      >
        <div class="section">
          <CountDown></CountDown>
          <h4 class="text-$theme-color text-[20px] mt-[45px] mb-[10px]">
            Payment Options
          </h4>
          <div
            class="detail flex justify-between items-center rounded-[26PX] border border-$theme-color p-[20px] mb-[12px]"
            v-for="item in payTypeList"
            :key="item.code"
            @click="selectPay(item)"
          >
            <div class="detail-title">
              <h4 class="text-[24px] font-normal text-$theme-color">
                {{ item.title }}
              </h4>
            </div>
            <div
              class="select w-[24px] h-[24px] bg-$theme-color-light-1 rounded-1/2 flex justify-center items-center"
            >
              <div
                class="w-[16px] h-[16px] rounded-1/2 bg-$theme-color"
                v-if="selectCode === item.code"
              ></div>
            </div>
          </div>
        </div>
        <button
          class="rounded-[26PX] bg-$theme-color text-$color-fff w-full h-[64px] text-[32px]"
          :class="{ opcity3: !selectCode }"
          @click="next('phone')"
        >
          PAY
        </button>
      </div>
      <!-- 首页移动端隐藏 -->
      <RouterView
        class="flex-1 view-box"
        :class="{ 'hidden-md': routePath === '/' }"
      ></RouterView>
    </div>
  </div>
</template>

<script setup>
import { useRouter, useRoute } from "vue-router";
import { onMounted, ref, computed } from "vue";
import { useStore } from "vuex";
import openModal from "../utils/dialog";
import CountDown from "@/components/CountDown.vue";
import { pagePayPayment, getPaymentResult, getPayOptions } from "@/api/payment";
import {openNewWindow} from "@/utils/utils";

const store = useStore();
const router = useRouter();
const route = useRoute();

const routePath = ref("/");
routePath.value = route.path;

router.beforeEach((to, from, next) => {
  routePath.value = to.path;
  next(); // 必须调用next()来resolve这个钩子
});

// 可选支付列表
let payTypeList = ref([
  {
    code: "qrCode",
    title: "UPI QR",
    option: "UPI QR",
  },
  {
    code: "upi",
    title: "UPI",
    option: "UPI",
  },
  // {
  //   code: "cards",
  //   title: "Cards",
  //   option: "Cards",
  // },
  // {
  //   code: "pagePay",
  //   title: "Netbanking",
  //   option: "Netbanking",
  // },
]);
// 选中支付方式
let selectCode = ref("");
// 计算第一个ops的code
let firstOpsCode = computed(() => payTypeList.value[0].code);
// 默认路由
let pathCode = routePath.value.replace("/", "");
// 默认选中 优先路由 默认 第一个
selectCode.value = pathCode || "";
// 窗口变化
function handleResize() {
  // 大于1280 && 首页（首页 / replace ''）
  let pathCode = routePath.value.replace("/", "");
  if (window.innerWidth > 1280 && !pathCode) {
    // 默认选中第一个 并跳转
    selectCode.value = firstOpsCode.value;
    router.replace({
      path: `/${selectCode.value}`,
    });
  }
}
onMounted(() => {
  handleResize();
  window.addEventListener("resize", handleResize);
});
// 获取支付列表
let getOpsList = async () => {
  const data = await getPayOptions();
  if (data && data.length) {
    // 过滤
    payTypeList.value = payTypeList.value.filter((item) =>
      data.some((pay) => pay.paymentMethod === item.option)
    );
  }
};

onMounted(() => getOpsList());

// pc端 选择支付方式切换
const selectForward = (item) => {
  if (store.state.opsDisable) {
    return false;
  }
  selectPay(item);
  next();
};
// 点击支付方式
const selectPay = (item) => {
  selectCode.value = item.code;
};

let dialog = null;

// 下一步
const next = (type) => {
  if (selectCode.value === "pagePay" || selectCode.value === "cards") {
    // loading
    store.commit("updateOpsStatus", true);
    dialog = openModal({
      title: "Waiting for payment...",
      content: "Please login to your app and accept payment from MPurse",
      type: "loading",
      cancelText: "",
      confirmText: "",
    });
    //唤起第三方支付
    getPagePay(dialog);
  } else {
    // 拉起手机
    router.replace({
      path: `/${selectCode.value}`,
      query: {
        type,
      },
    });
  }
};
let getResult;
//UPI支付结果
let upiResult = async () => {
  getPaymentResult({}, { noAlert: true })
    .then((data) => {
      if (data.status === "SUCCESS" || data.status === "FAILURE" || data.status === "CLOSED") {
        //已支付或支付失败
        clearTimeout(getResult);
        dialog.unmount(); //关闭Waiting
        router.replace({
          path: "/paymentResult",
          // query: {
          //   type: "UPI Collect",
          // },
        });
      } else {
        //未支付重试
        clearTimeout(getResult);
        getResult = setTimeout(() => {
          upiResult();
        }, 1000);
      }
    })
    .catch((err) => {
      //未支付重试
      clearTimeout(getResult);
      getResult = setTimeout(() => {
        upiResult();
      }, 1000);
    });
};
// 发起第三方支付
let getPagePay = (dialog) => {
  pagePayPayment()
    .then((data) => {
      openNewWindow(data)
      // window.open(data, "_blank", "width=1300,height=800,top = 200, left=400");
      // 查询支付结果 第三方支付窗口是否关闭childrenWin.closed
      upiResult();
    })
    .catch((err) => {
      dialog.unmount();
      store.commit("updateOpsStatus", false);
      // router.replace({
      //   path: "/paymentResult",
      //   query: {
      //     type: "Pay through Bank",
      //   },
      // });
    });
};
//关闭窗口
const close = () => {
  dialog = openModal({
    onCancel: () => {
      let redirectLink = sessionStorage.getItem("redirectLink");
      if (redirectLink) {
        // 有重定向地址跳转
        window.location.replace(redirectLink)
      } else {
        // 无重定向返回 通过window.open方式打开关闭窗口
        const isOPen = window.opener && window.opener !== window;
        isOPen && window.close();
        router.back();
      }
    },
  });
};
</script>
